const addInfo = {
  title: 'Notification',
  icon: 'notification'
}
const notificationRouter = {
  path: '/notification',
  hidden: true,
  meta: {
    title: 'Notification',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/Notification/NotificationList'),
      name: 'Notification',
      meta: {
        title: 'Notification',
        parent: addInfo
      }
    }
  ]
}

export default notificationRouter