const addInfo = {
    title: 'GpsTracking',
    icon: 'GpsTracking'
  }
const GPSTrackingRouter = {
    path: '/gps-tracking',
    meta: {
        title: 'GpsTracking',
        icon: 'GpsTracking'
    },
    children: [
        {
            path: 'full-map-tracking/:id?',
            component: () => import('@/views/GPS-Tracking/FullMapTracking/full-map-tracking.vue'),
            name: 'FullMapTracking',
            meta: { title: 'FullMapTracking', parent: addInfo }
        },
        {
            path: 'multi-vehicle-tracking',
            component: () => import('@/views/GPS-Tracking/MultiVehicleTracking/multi-vehicle-tracking.vue'),
            name: 'MultiVehicleTracking',
            meta: { title: 'MultiVehicleTracking', parent: addInfo }
        },
        {
            path: 'vehicle-tracking-list',
            component: () => import('@/views/GPS-Tracking/VehicleTrackingList/vehicle-tracking-list.vue'),
            name: 'VehicleTrackingList',
            meta: { title: 'VehicleTrackingList', parent: addInfo }
        },
        {
            path: 'historical-playback/:id?',
            component: () => import('@/views/GPS-Tracking/HistoricalPlayback/historical-playback-details.vue'),
            name: 'HistoricalPlaybackDetails',
            meta: { title: 'HistoricalPlayback', activeMenu: '/gps-tracking/historical-playback' }
        },
        {
            path: 'poi-management',
            component: () => import('@/views/GPS-Tracking/PoiManagement/poi-management.vue'),
            name: 'PoiManagement',
            meta: { title: 'PoiManagement', parent: addInfo }
        },
        {
            path: 'poi-form/:id?',
            component: () => import('@/views/GPS-Tracking/PoiManagement/poi-form'),
            name: 'PoiForm',
            hidden: true,
            meta: { title: 'PoiManagement', parent: addInfo, activeMenu: '/gps-tracking/poi-management' }
        },
        {
            path: 'map-instance',
            component: () => import('@/views/GPS-Tracking/MapInstance/map-instance.vue'),
            name: 'MapInstance',
            meta: { title: 'MapInstance', parent: addInfo }
        },
        {
            path: 'map-instance-details/:id',
            component: () => import('@/views/GPS-Tracking/MapInstance/map-instance-details.vue'),
            name: 'MapInstanceDetails',
            hidden: true,
            meta: { title: 'MapInstanceDetails', parent: addInfo, enableBack: true, backPage: 'MapInstance', activeMenu: '/gps-tracking/map-instance' }
        },
        // {
        //     path: 'historical-playback',
        //     component: () => import('@/views/GPS-Tracking/HistoricalPlayback/historical-playback-details.vue'),
        //     name: 'HistoricalPlayback',
        //     meta: { title: 'HistoricalPlayback' },
        // },
        {
            path: 'pto-log',
            component: () => import('@/views/GPS-Tracking/PtoLog/pto-log.vue'),
            name: 'PtoLog',
            meta: { title: 'PtoLog', parent: addInfo }
        },
    ]
}

export default GPSTrackingRouter
