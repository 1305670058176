const addInfo = {
  title: 'FleetAccessSettings',
  icon: 'fleet-access-settings'
}
const fleetAccessSettingsRouter = {
  path: '/fleet-access-settings',
  meta: {
    title: 'FleetAccessSettings',
    icon: 'fleet-access-settings'
  },
  children: [
    {
      path: 'manage-user',
      component: () => import('@/views/fleet-access-settings/ManageUser/manage-user'),
      name: 'ManageUser',
      meta: { title: 'ManageUser', parent: addInfo }
    },
    {
      path: 'user-form/:id?',
      component: () => import('@/views/fleet-access-settings/ManageUser/user-form'),
      hidden: true,
      name: 'UserForm',
      meta: { title: 'UserForm', parent: addInfo, activeMenu: '/fleet-access-settings/manage-user' }
    },
    {
      path: 'manage-lite-access',
      component: () => import('@/views/fleet-access-settings/ManageLite/manage-lite-access'),
      name: 'ManageLite',
      meta: { title: 'ManageLite', parent: addInfo }
    },
    {
      path: 'lite-user-form/:id?',
      component: () => import('@/views/fleet-access-settings/ManageLite/lite-user-form'),
      name: 'LiteUserForm',
      hidden: true,
      meta: { 
        title: 'LiteUserForm', 

        activeMenu: '/fleet-access-settings/manage-lite-access',
        enableBack: true,
        backPage: 'ManageLite',
        parent: addInfo }
    },
    {
      path: 'fleet-group-management',
      component: () => import('@/views/fleet-access-settings/FleetGroupManagement/fleet-group-management'),
      name: 'FleetGroupManagement',
      meta: { title: 'FleetGroupManagement', parent: addInfo }
    },
    {
      path: 'fleet-group-form/:id?',
      component: () => import('@/views/fleet-access-settings/FleetGroupManagement/fleet-group-form'),
      hidden: true,
      name: 'FleetGroupForm',
      meta: { title: 'FleetGroupForm', parent: addInfo, activeMenu: '/fleet-access-settings/fleet-group-management', enableBack: true, backPage: 'FleetGroupManagement' }
    },
    // {
    //   path: 'company-management',
    //   component: () => import('@/views/fleet-access-settings/CompanyManagement/company-management'),
    //   name: 'CompanyManagement',
    //   meta: { title: 'CompanyManagement', parent: addInfo }
    // },
    // {
    //   path: 'company-form/:id?',
    //   component: () => import('@/views/fleet-access-settings/CompanyManagement/company-form'),
    //   hidden: true,
    //   name: 'CompanyForm',
    //   meta: { title: 'CompanyForm', parent: addInfo, activeMenu: '/fleet-access-settings/company-management' }
    // },
    // {
    //   path: 'email-notification-settings',
    //   component: () => import('@/views/fleet-access-settings/EmailNotificationSettings/email-notification-settings'),
    //   name: 'EmailNotificationSettings',
    //   meta: { title: 'EmailNotificationSettings', parent: addInfo }
    // },
    // {
    //   path: 'email-notification-settings-form/:id?',
    //   component: () => import('@/views/fleet-access-settings/EmailNotificationSettings/email-notification-form'),
    //   hidden: true,
    //   name: 'EmailNotificationSettingsForm',
    //   meta: { title: 'EmailNotificationSettingsForm', parent: addInfo, activeMenu: '/fleet-access-settings/email-notification-settings' }
    // },
    {
      path: 'roles-management',
      component: () => import('@/views/fleet-access-settings/RolesManagement/roles-management'),
      name: 'RolesManagement',
      meta: { title: 'RolesManagement', parent: addInfo }
    },
    {
      path: 'roles-form/:id?',
      component: () => import('@/views/fleet-access-settings/RolesManagement/roles-form'),
      hidden: true,
      name: 'RolesForm',
      meta: { title: 'RolesForm', parent: addInfo, activeMenu: '/fleet-access-settings/roles-management', enableBack: true, backPage: 'RolesManagement' }
    },
    {
      path: 'package-management',
      component: () => import('@/views/fleet-access-settings/PackageManagement/package-management'),
      name: 'PackageManagement',
      meta: { title: 'PackageManagement', parent: addInfo }
    },
    {
      path: 'package-form/:id?',
      component: () => import('@/views/fleet-access-settings/PackageManagement/package-form'),
      hidden: true,
      name: 'PackageForm',
      meta: { title: 'PackageForm', parent: addInfo, activeMenu: '/fleet-access-settings/package-management', enableBack: true, backPage: 'PackageManagement' }
    },
    {
      path: 'audit-logs',
      component: () => import('@/views/fleet-access-settings/AuditLogs/audit-logs'),
      name: 'AuditLogs',
      meta: { title: 'AuditLogs', parent: addInfo }
    }
  ]
}

export default fleetAccessSettingsRouter
