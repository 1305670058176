import { defineStore } from 'pinia'
import { constantRoutes, asyncRoutes, liteRoutes } from '@/router'

export const useAppStore = defineStore('app', {
  state: () => ({
    sidebar: localStorage.getItem("sidebarStatus") || true,
    device: 'desktop',
    mapType: localStorage.getItem("mapType") || 'OSM',
    shortcut: []
  }),
  actions: {
    toggleSideBar() {
      this.sidebar = !this.sidebar
      if (this.sidebar) {
        localStorage.setItem("sidebarStatus", "true")
      } else {
        localStorage.setItem("sidebarStatus", "false")
      }
    },
    closeSideBar() {
      localStorage.setItem("sidebarStatus", "false")
      this.sidebar = false
    },
    toggleDevice(val) {
      this.device = val
    },
    toggleMapType(val) {
      // if (this.mapType === 'OSM') {
      //   localStorage.setItem("mapType", 'GM')
      //   this.mapType = 'GM'
      // } else {
      //   localStorage.setItem("mapType", 'OSM')
      //   this.mapType = 'OSM'
      // }
      localStorage.setItem("mapType", val)
      this.mapType = val
      // window.location.reload()
    },
    updateShortcut(val) {
      this.shortcut = val
    }
  },
})

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: () => ({
    category: '',
    report: ''
  }),
  actions: {
    update(val) {
      this.category = val.category
      this.report = val.report
    }
  }
})

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    roles: 'admin',
    routes: [],
  }),
  actions: {
    generateRoutes() {
      this.routes = constantRoutes.concat(asyncRoutes)
      return this.routes
    }
  }
})

export const usePermissionStoreLite = defineStore('permissionLite', {
  state: () => ({
    roles: 'admin', //need change not?
    routes: [],
  }),
  actions: {
    generateRoutes() {
      this.routes = liteRoutes
      return this.routes
    }
  }
})