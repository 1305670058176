import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { usePermissionStore, usePermissionStoreLite } from '@/store/store'
import { getLite } from '@/utils/auth'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

import router from '@/router'
import { getToken } from '@/utils/auth'
// import { ElNotification } from "element-plus"
// import i18n from "@/local/i18n.js"

router.beforeEach(async (to, from, next) => {
    // console.log(to, from, 'router before each')
    // start progress bar
    NProgress.start()

    const permission = usePermissionStore()
    const permissionLite = usePermissionStoreLite()

    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login' || to.path === '/') {
            if (getLite() === 'true') {
                next({ path: '/gps-tracking/full-map-tracking-lite' })
            } else {
                next({ path: '/gps-tracking/full-map-tracking' })
            }
            NProgress.done()
        } else {
            let access = null
            if(getLite()==='true'){
                access = permissionLite.routes
            }else{
                access = permission.routes
            }
            if (access.length > 0) {
                next()
            } else {
                // const permissions = await permission.getPermission()
                let accessRoutes = null
                if(getLite()==='true'){
                    accessRoutes = await permissionLite.generateRoutes()
                }else{
                    accessRoutes = await permission.generateRoutes()
                }
                if (accessRoutes.length > 0) {
                    // dynamically add accessible routes
                    accessRoutes.forEach(route => {
                        router.addRoute(route)
                    })
                    router.addRoute({
                        path: '/:pathMatch(.*)',
                        redirect: '/404'
                    })
                    // await router.replace(router.currentRoute.value.fullPath)
                }
                next({ ...to, replace: true })
            }
        }
    } else {
        if (to.meta.bypass) {
            next()
        } else {
            next('login')
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
