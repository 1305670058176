const addInfo = {
  title: 'Report',
  icon: 'report'
}
const reportRouter = {
  path: '/report',
  meta: {
    title: 'Report',
    icon: 'report'
  },
  children: [
    {
      path: 'report-list',
      component: () => import('@/views/report'),
      name: 'ReportList',
      meta: { title: 'Report', parent: addInfo }
    },
    {
      path: 'trips',
      hidden: true,
      meta: { title: 'Trips', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'all-vehicle-summary-report',
          component: () => import('@/views/report/Trips/all-vehicle-summary-report'),
          name: 'all_vehicle_summary_report',
          hidden: true
        },
        {
          path: 'vehicle-usage-report',
          component: () => import('@/views/report/Trips/vehicle-usage-report'),
          name: 'vehicle_usage_report',
          hidden: true
        },
        {
          path: 'trips-report',
          component: () => import('@/views/report/Trips/trips-report'),
          name: 'trips_report',
          hidden: true
        },
        {
          path: 'geofence-report',
          component: () => import('@/views/report/Trips/geofence-report'),
          name: 'geofence_report',
          hidden: true
        },
        {
          path: 'poi-report',
          component: () => import('@/views/report/Trips/poi-report'),
          name: 'poi_report',
          hidden: true
        }
      ]
    },
    {
      path: 'vehicle-performance',
      hidden: true,
      meta: { title: 'VehiclePerformance', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'vehicle-status-log',
          component: () => import('@/views/report/VehiclePerformance/vehicle-status-log'),
          name: 'vehicle_status_log',
          hidden: true
        },
        {
          path: 'machinery-summary-report',
          component: () => import('@/views/report/VehiclePerformance/machinery-summary-report'),
          name: 'machinery_summary_report',
          hidden: true
        },
        {
          path: 'maintenance-log-report',
          component: () => import('@/views/report/VehiclePerformance/maintenance-log-report'),
          name: 'maintenance_log_report',
          hidden: true
        },
        {
          path: 'idling-summary-report',
          component: () => import('@/views/report/VehiclePerformance/idling-summary-report'),
          name: 'idling_summary_report',
          hidden: true
        },
        {
          path: 'raw-data-report',
          component: () => import('@/views/report/VehiclePerformance/raw-data-report'),
          name: 'raw_data_report',
          hidden: true
        },
        {
          path: 'engine-report',
          component: () => import('@/views/report/VehiclePerformance/engine-report'),
          name: 'engine_report',
          hidden: true
        },
        {
          path: 'temperature-chart',
          component: () => import('@/views/report/VehiclePerformance/temperature-chart'),
          name: 'temperature_chart',
          hidden: true
        },
        {
          path: 'pto-camera-report',
          component: () => import('@/views/report/VehiclePerformance/pto-camera-report'),
          name: 'pto_camera_report',
          hidden: true
        },
        {
          path: 'rotation-report',
          component: () => import('@/views/report/VehiclePerformance/rotation-report'),
          name: 'rotation_report',
          hidden: true
        },
        {
          path: 'driving-record-report',
          component: () => import('@/views/report/VehiclePerformance/driving-record-report'),
          name: 'driving_record_report',
          hidden: true
        },
      ]
    },
    {
      path: 'trailer',
      hidden: true,
      meta: { title: 'Trailer', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'trailer-log-report',
          component: () => import('@/views/report/Trailer/trailer-log-report'),
          name: 'trailer_log_report',
          hidden: true
        },
        {
          path: 'trailer-raw-report',
          component: () => import('@/views/report/Trailer/trailer-raw-report'),
          name: 'trailer_raw_report',
          hidden: true
        }
      ]
    },
    {
      path: 'driver',
      hidden: true,
      meta: { title: 'Driver', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'driver-summary-report',
          component: () => import('@/views/report/Driver/driver-summary-report'),
          name: 'driver_summary_report',
          hidden: true
        },
        {
          path: 'driver-logs-report',
          component: () => import('@/views/report/Driver/driver-logs-report'),
          name: 'driver_logs_report',
          hidden: true
        },
        {
          path: 'driver-trips-report',
          component: () => import('@/views/report/Driver/driver-trips-report'),
          name: 'driver_trips_report',
          hidden: true
        },
        {
          path: 'driver-activity-report',
          component: () => import('@/views/report/Driver/driver-activity-report'),
          name: 'driver_activity_report',
          hidden: true,
        },
        {
          path: 'driver-activity-summary-report',
          component: () => import('@/views/report/Driver/driver-activity-summary-report'),
          name: 'driver_activity_summary_report',
          hidden: true
        },
      ]
    },
    {
      path: 'driver-app',
      hidden: true,
      meta: { title: 'DriverApp', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'driver-job-report',
          component: () => import('@/views/report/DriverApp/driver-job-report'),
          name: 'driver_job_report',
          hidden: true
        },
        {
          path: 'get-checklist-report',
          component: () => import('@/views/report/DriverApp/get-checklist-report'),
          name: 'get_checklist_report',
          hidden: true
        }
      ]
    },
    {
      path: 'safety',
      hidden: true,
      meta: { title: 'Safety', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'vehicle-safety-report',
          component: () => import('@/views/report/Safety/vehicle-safety-report'),
          name: 'vehicle_safety_report',
          hidden: true
        },
        {
          path: 'fatigue-photo-video-report',
          component: () => import('@/views/report/Safety/fatigue-photo-video-report'),
          name: 'fatigue_photo_video_report',
          hidden: true
        },
        {
          path: 'fatigue-monitor-board',
          component: () => import('@/views/report/Safety/fatigue-monitor-board'),
          name: 'fatigue_monitor_board',
          hidden: true
        },
        {
          path: 'fatigue-driving-record/:id',
          component: () => import('@/views/report/Safety/fatigue-driving-record'),
          name: 'fatigue_driving_record',
          hidden: true
        },
      ]
    },
    {
      path: 'spend',
      hidden: true,
      meta: { title: 'Spend', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'toll-report',
          component: () => import('@/views/report/Spend/toll-report'),
          name: 'toll_report',
          hidden: true
        },
      ]
    },
    {
      path: 'fuel',
      hidden: true,
      meta: { title: 'Fuel', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'fuel-drop-report',
          component: () => import('@/views/report/Fuel/fuel-drop-report'),
          name: 'fuel_drop_report',
          hidden: true
        },
        {
          path: 'refuel-summary-report',
          component: () => import('@/views/report/Fuel/refuel-summary-report'),
          name: 'refuel_summary_report',
          hidden: true
        },
        {
          path: 'mileage-liter-by-statement',
          component: () => import('@/views/report/Fuel/mileage-liter-by-statement'),
          name: 'mileage_liter_by_statement',
          hidden: true
        },
      ]
    },
    {
      path: 'operator',
      hidden: true,
      meta: { title: 'Operator', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'operator-company-daily-report',
          component: () => import('@/views/report/Operator/operator-company-daily-report'),
          name: 'operator_company_daily_report',
          hidden: true
        },
        {
          path: 'operator-attendance-report',
          component: () => import('@/views/report/Operator/operator-attendance-report'),
          name: 'operator_attendance_report',
          hidden: true
        },
        {
          path: 'operator-driver-report',
          component: () => import('@/views/report/Operator/operator-driver-report'),
          name: 'operator_driver_report',
          hidden: true
        },
      ]
    },
    {
      path: 'alert',
      hidden: true,
      meta: { title: 'Alert', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'suspicious-activity-report',
          component: () => import('@/views/report/Alert/suspicious-activity-report'),
          name: 'suspicious_activity_report',
          hidden: true
        },
        {
          path: 'critical-alert-report',
          component: () => import('@/views/report/Alert/critical-alert-report'),
          name: 'critical_alert_report',
          hidden: true
        },
        {
          path: 'alert-report',
          component: () => import('@/views/report/Alert/alert-report'),
          name: 'alert_report',
          hidden: true
        },
        {
          path: 'maintenance-alert-report',
          component: () => import('@/views/report/Alert/maintenance-alert-report'),
          name: 'maintenance_alert_report',
          hidden: true
        },
      ]
    },
    {
      path: 'diagnosis',
      hidden: true,
      meta: { title: 'Diagnosis', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'vehicle-diagnosis-report',
          component: () => import('@/views/report/Diagnosis/vehicle-diagnosis-report'),
          name: 'vehicle_diagnosis_report',
          hidden: true
        },
      ]
    },
    {
      path: 'speed',
      hidden: true,
      meta: { title: 'Speed', parent: addInfo, activeMenu: '/report/report-list' },
      children: [
        {
          path: 'speed-report',
          component: () => import('@/views/report/Speed/speed-report'),
          name: 'speed_report',
          hidden: true
        },
        {
          path: 'overspeed-record-report',
          component: () => import('@/views/report/Speed/overspeed-record-report'),
          name: 'overspeed_record_report',
          hidden: true
        },
      ]
    },
  ]
}

export default reportRouter
