<template>
  <el-config-provider :locale="lang === 'en' ? en : ms">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import en from 'element-plus/dist/locale/en'
import ms from '@/local/languages/element-plus-ms'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const lang = computed(() => locale.value)

</script>