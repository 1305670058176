const addInfo = {
  title: 'EventCalendar',
  icon: 'event-calendar'
}
const eventCalendarRouter = {
  path: '/event-calendar',
  meta: {
    title: 'EventCalendar',
    icon: 'event-calendar'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/EventCalendar/EventList/list'),
      name: 'EventCalendarList',
      meta: { title: 'EventList', parent: addInfo }
    },
    {
      path: 'calendar',
      component: () => import('@/views/EventCalendar/Calendar/calendar'),
      name: 'EventCalendarCalendar',
      meta: { title: 'Calendar', parent: addInfo }
    },
  ]
}

export default eventCalendarRouter